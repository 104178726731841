<template>
  <div class="r-cover overflow-hidden bg-grey-500">
    <div class="r-cover overflow-auto px-5">
      <div class="w-full max-w-4xl mx-auto pb-10 pt-28 md:pt-40">
        <div class="md:flex">
          <div class="pb-8 md:pb-0">
            <h1 class="heading athelas-font text-4xl md:text-6xl font-normal mb-10 leading-none">
              {{ page.title.rendered }}
            </h1>
            <div class="wysiwyg" v-html="page.content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapGetters } from 'vuex'

export default {
  name: 'Page',

  mounted() {
    gsap.fromTo('.heading', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 0.2, duration: 0.6, ease: 'power1.inOut' })
    gsap.fromTo('.wysiwyg', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 0.4, duration: 0.6, ease: 'power1.inOut' })
  },

  watch: {
    $route() {
      gsap.fromTo('.heading', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 0.2, duration: 0.6, ease: 'power1.inOut' })
      gsap.fromTo('.wysiwyg', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 0.4, duration: 0.6, ease: 'power1.inOut' })
    }
  },

  computed: {
    ...mapGetters(['getPageBySlug']),
    page() {
      return this.getPageBySlug(this.$route.params.page)
    }
  }
}
</script>
